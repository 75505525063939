@import "../../assets/styles/mixins";

.Boards{
  .boards-top {
    display: flex;
    justify-content: space-between;
  }
  .boards-filters{
    display: flex;
    justify-content: flex-end;
    margin-bottom: 24px;
    .dropDown{
      border: 1px solid var(--color-grey-30);
      border-radius: 16px;
      display: flex;
      margin-left: 8px;
      overflow: hidden;
      & >.MuiButtonBase-root{
        padding: 8px 12px;
        &.filtered{
          background-color: var(--color-tahakom-opacity);
        }
      }
    }
  }
  .managers{
    display: flex;
    overflow-x: auto;
    height: calc(100vh - var(--header-height) - 82px);
    margin-right: -24px;
    @include scrollbar(false, "&");

    .managers-item{
      min-width: 320px;
      display: flex;
      flex-direction: column;
      &:not(:last-of-type){
        margin-right: 32px;
      }
      .managers-title{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;
        h3{
          font-family: 'IBMPlexSansMedium', sans-serif;;
          font-size: 18px;
          line-height: 24px;
          color: var(--color-black-light);
          span{
            color: var(--color-grey-70);
            margin-left: 5px;
          }
        }
        .managers-sort{
          font-family: 'IBMPlexSansMedium', sans-serif;;
          font-size: 10px;
          line-height: 14px;
          text-align: right;
          text-transform: uppercase;
          color: var(--color-grey-70);
          display: flex;
          align-items: center;
          cursor: pointer;
          svg{
            margin-left: 8px;
            path{
              transition: .3s;
            }
          }
          &:hover path{
            fill: var(--color-black-light)
          }
        }
      }
      .button{
        margin-bottom: 8px;
      }
      .no-tickets{
        .no-tickets-info{
          display: flex;
          align-items: center;
          font-family: 'IBMPlexSansMedium', sans-serif;;
          font-size: 14px;
          line-height: 16px;
          color: var(--color-grey-70);
          span{
            padding: 8px;
            background: var(--color-grey-10);
            border-radius: 50%;
            display: flex;
            margin-right: 16px;
            svg{
              width: 24px;
              height: 24px;
            }
          }
        }
        .button{
          margin-top: 24px;
        }
      }
      .managers-droppable{
        height: 100%;
        border: 1px dashed transparent;
        &.active{
          background: rgba(5, 121, 223, 0.1);
          border-color: var(--color-primary);
          //box-sizing: border-box;
          border-radius: 4px;
          .no-tickets{
            display: none;
          }
        }
        &.current{
          background: rgba(243, 38, 65, 0.1);
        }
        .ticket{
          cursor: pointer;
        }
      }
    }
  }
  .priority-Low{
    color: var(--color-additional-3)!important;
    path{
      fill: var(--color-additional-3);
    }
  }
  .priority-Medium{
    color: var(--color-pending)!important;
    path{
      fill: var(--color-pending);
    }
  }
  .priority-High,
  .priority-Urgent,
  .priority-Critical{
    color: var(--color-waiting)!important;
    path{
      fill: var(--color-waiting);
    }
  }
}
