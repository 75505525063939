@font-face {
  font-family: 'IBMPlexSansRegular';
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/IBMPlexSans/IBMPlexSans-Regular.ttf") format('woff');
}

@font-face {
  font-family: 'IBMPlexSansMedium';
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/IBMPlexSans/IBMPlexSans-Medium.ttf") format('woff');
}