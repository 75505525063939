.auth-page {
  background-image: url('../../assets/img/auth-bg.jpg');
  background-size: cover;
  width: 100%;
  height: 100vh;
  position: relative;

  .right {
    width: 50%;
    height: 100%;
    background-color: #fff;
    position: absolute;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: safe center;
    overflow: auto;

    .logo {
      margin-top: 46px;
    }

    .auth-card {
      padding: 50px 0;
      margin-top: 50px;
      margin-bottom: 50px;
      width: 80%;
      // height: 680px;
      background: #FFFFFF;
      border: 1px solid #A6A2A2;
      border-radius: 19px;

      display: flex;
      flex-direction: column;
      align-items: center;

      .MuiFormControl-root{
        width: 100%;
      }

      .auth-card-head {
        text-align: center;
        width: 80%;
        margin-bottom: 10px;
        h2 {
          font-size: 32px;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;

          a {
            position: absolute;
            left: 10px;
            cursor: pointer;
          }
        }

        span {
          margin: 12px;
          color: #A6A2A2;
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 14px;

          &::before {
            content: "";
            position: absolute;
            left: calc(42% - 57px);
            top: 10px;
            width: 57px;
            height: 1px;
            background-color: #B9C0CC;
          }

          &::after {
            content: "";
            position: absolute;
            right: calc(42% - 57px);
            top: 10px;
            width: 57px;
            height: 1px;
            background-color: #B9C0CC;
          }
        }

        .auth-request {
          color: #0579DF;
        }
      }

      .auth-card-form {
        width: 80%;
        position: relative;

        .auth-field{
          margin-bottom: 24px;
        }

        .MuiInputBase-root{
          background-color: var(--color-grey-10);
          padding-right: 0;
        }
        .MuiInputAdornment-root{
          height: 100%;
          cursor: pointer;
          position: absolute;
          right: 12px;
        }

        h6{
          display: flex;
          font-size: 14px;
          line-height: 16px;
          color: var(--color-black-light);
          margin-bottom: 6px;
        }

        .fPass {
          color: #0579DF;
          display: flex;
          justify-content: flex-end;
          font-size: 14px;
          cursor: pointer;
          margin-top: -8px;
        }

        .remember {
          color: #838282;
          font-size: 14px;
          font-family: 'IBMPlexSansRegular', sans-serif;
          display: flex;
          align-items: center;
          margin-top: 60px;

          input {
            margin-right: 10px;
          }
        }

        .auth-failed, .auth-success {
          text-align: center;
          font-size: 14px;
          display: block;
          margin-top: 10px;
        }
        .auth-failed {
          color: red;
        }
        .auth-success {
          color: green;
        }

        button {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 16px;
          width: 100%;
          height: 56px;
          border: none;
          background: #FF8800;
          border-radius: 4px;
          color: #fff;
          cursor: pointer;
          font-size: 18px;
          font-family: 'IBMPlexSansMedium', sans-serif;
          transition: .3s;
          margin-top: 20px;
          &:hover{
            background: #ebb679;
          }
        }

        button.loading {
          background: #ebb679;
        }

        button.formForget {
          margin-top: 190px;
          margin-bottom: 68px;
        }

        button.formReset {
          margin-top: 108px;
          margin-bottom: 68px;
        }

        .loginO365 {
          margin: 12px;
          color: #415256;
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 16px;

          &::before {
            content: "";
            position: absolute;
            left: calc(28% - 57px);
            top: 10px;
            width: 57px;
            height: 1px;
            background-color: #B9C0CC;
          }

          &::after {
            content: "";
            position: absolute;
            right: calc(28% - 57px);
            top: 10px;
            width: 57px;
            height: 1px;
            background-color: #B9C0CC;
          }
        }

        .loginO365-image {
          display: flex;
          width: 100%;
          justify-content: center;
          margin: 30px 0;

          span {
            background: #ECECEC;
            border-radius: 50%;
            width: 62px;
            height: 59px;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            img {
              position: absolute;
              left: -8px;
            }
          }
        }
      }
    }
  }

  @media (max-width: 1110px) {
    .right {
      width: 100%;
    }
  }
}