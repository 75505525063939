.dropDown {
  p, .MuiButtonBase-root{
    font-size: 14px;
    line-height: 16px;
    color: var(--color-black-light);
    font-family: 'IBMPlexSansMedium', sans-serif;
    text-transform: none;
  }
  button:not(.button) {
    padding: 0;
    &:hover{
      background-color: transparent;
    }
  }
  svg, img{
    width: 16px;
    height: 16px;
  }
  svg{
    transition: .3s;
    opacity: .7;
    padding: 2px;
    path{
      fill: var(--color-black-light);
    }
    &:hover{
      opacity: 1;
    }
  }

  .clear{
    margin-left: 10px;
  }
  .dropDown-items{
    .MuiMenuItem-root {
      padding: 0;
    }
  }
}