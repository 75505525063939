.left-right-switch {
  display: flex;
  flex-direction: row;
  border: 1px solid var(--color-grey-20);
  border-radius: 4px;
  justify-content: space-between;
  align-items: center;
  width: auto;

  img.icon {
    user-select: none;
    padding: 8px;
  }

  span {
    display: flex;
    user-select: none;
    flex: 1;
    font-size: 14px;
    color: var(--color-black-light);
    padding: 0 8px;
  }

  .right-icons {
    padding: 0 8px;
    display: flex;

    img {
      user-select: none;

      &:hover {
        cursor: pointer;
        opacity: .5;
      }

      &.disabled {
        visibility: hidden;
      }
    }

    img.left {
      padding: 8px;
      width: auto;
      margin-right: 8px;
    }

    img.right {
      padding: 8px;
      width: auto;
    }
  }
}
