
.column-option-row {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }

  .checkbox-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 80%;
    word-break: break-word;

    img.reorder {
      width: 24px;
      height: auto;
    }

    .checkbox {
      margin: 0 16px 0 4px;
    }

    p {
      font-size: 14px;
      line-height: 16px;
      color: var(--color-black-light);
    }
  }

  .freeze-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 20%;
    min-width: 20%;

    img {
      display: flex;
      flex: 1;
      padding: 2px;

      &:hover {
        cursor: pointer;
        opacity: .5;
      }
    }
  }
}