@import "../../assets/styles/mixins";

.header {
  position: fixed;
  display: flex;
  height: auto;
  background-color: var(--color-white);
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 25;
  user-select: none;
  left: 0;
  right: 0;
  border-bottom: 1px solid #EEEEEE;
  padding: 0 14px;
  transition: .3s;

  .section {
    display: flex;
    height: var(--header-height);
    align-items: center;
    &.left{
      flex: none;
      h2{
        font-size: 18px;
        line-height: 24px;
      }
      .logo {
        transition: .3s;
        margin-right: 24px;
        img{
          max-width: 100%;
          height: 40px;
        }
        &:hover{
          opacity: .7;
        }
      }
    }
    &.right {
      justify-content: flex-end;
      width: 100%;

      .dropdown {
        display: flex;
        flex-direction: row;
        height: 100%;
        justify-content: center;
        align-items: center;
        padding: 0 16px;
        //max-width: 160px;
        text-overflow: ellipsis;
        color: black;
        min-width: 180px;
        cursor: pointer;
        transition: .3s;
        &:hover{
          opacity: .7;
        }
        svg{
          transition: .3s;
        }
        &.dropdownOpen{
          opacity: .7;
          svg{
            transform: rotate(180deg);
          }
        }

        .dropdown-content {
          li {
            padding: 5px;
            list-style-type: none;
            border-radius: 5px;
            &:hover {
              cursor: pointer;
              background-color: rgba(0, 0, 0, .25);
            }
          }
        }

        .name-holder {
          display: flex;
          flex-direction: column;
          flex-basis: 80%;
          margin-right: 12px;
          white-space: nowrap;

          p.big {
            font-size: 12px;
            line-height: 16px;
          }

          p.small {
            font-size: 10px;
            line-height: 14px;
          }
        }

        img {
          display: flex;
          flex-basis: 20%;
        }
      }

      .icon-buttons {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: 100%;
        position: relative;

        .icon-button {
          display: flex;
          padding: 8px;
          align-items: center;
          position: relative;
          border-radius: 50%;

          &:hover {
            cursor: pointer;
          }

          img {
            height: 24px;
            width: 24px;
          }
          &.active{
            background-color: var(--color-grey-10);
          }
        }
      }
    }
  }
}

.menu{
  .MuiMenu-paper{
    overflow: visible;
  }
  .MuiMenu-list{
    min-width: 100px;
    .MuiMenuItem-root{
      font-size: 14px;
      line-height: 16px;
      font-family: 'IBMPlexSansMedium', sans-serif;
      position: relative;
      overflow: visible;
      letter-spacing: initial;
      background-color: transparent;
      .subMenu{
        background: var(--color-white);
        border: 1px solid var(--color-grey-10);
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25), 3px 6px 6px rgba(0, 0, 0, 0.15);
        border-radius: 4px;;
        position: absolute;
        left: 95%;
        top: 0;
        width: 240px;
        li{
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          white-space: initial;
          span{
            display: block;
            font-size: 12px;
            line-height: 16px;
            color: var(--color-grey-70);
            font-family: "IBMPlexSansRegular", sans-serif;
          }
        }
      }
      &:hover{
        background-color: rgba(0, 0, 0, 0.04);
      }
    }
  }
}