.MuiDayCalendar-root {
  .MuiButtonBase-root{
    border-radius: 4px;
    font-size: 14px;
    line-height: 16px;
    font-family: 'IBMPlexSansMedium', sans-serif;
    &.Mui-selected{
      background-color: var(--color-primary)!important;
    }
    &.MuiPickersDay-today{
      background: var(--color-grey-10);
      border-radius: 4px;
      color: var(--color-black-light);
    }
  }

  .MuiDialogActions-root{
    padding-right: 15px;
    padding-left: 15px;
    button{
      font-size: 16px;
      line-height: 24px;
      font-family: 'IBMPlexSansMedium', sans-serif;
      color: var(--color-ongoing);
      background-color: var(--color-grey-10);
      text-transform: none;
      letter-spacing: normal;
    }
    button:last-of-type{
      background-color: var(--color-ongoing);
      color: var(--color-white);
    }
  }
}
.calendar-btns{
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  padding: 0 16px 24px;
}