.modal-wrapper.support-modal .modal-content{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 16px 32px;
  .modal-btns{
    width: 100%;
    margin-bottom: 16px;
  }
  .support-left{
    width: 58%;
  }
  .support-right{
    width: 40%;
  }
  .row-title{
    font-family: 'IBMPlexSansMedium', sans-serif;;
    font-size: 16px;
    line-height: 24px;
    color: var(--color-black-light);
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .row-key{
      width: auto;
      margin-right: 8px;
    }
  }
  .row-item{
    display: flex;
    font-size: 14px;
    line-height: 16px;
    font-weight: normal;
    flex: 0 0 auto;
    &:not(:last-of-type){
      margin-bottom: 16px;
    }
  }
  .row-key{
    color: var(--color-grey-70);
    width: 50%;
    flex: 0 0 auto;
  }
  .row-value{
    color: var(--color-black-light);
    span{
      color: var(--color-grey-70);
      word-break: break-all;
      display: block;
    }
  }

  .row-dates{
    display: flex;
    span{
      padding: 8px;
      background: var(--color-grey-10);
      border-radius: 4px;
      font-family: 'IBMPlexSansMedium', sans-serif;;
      font-size: 12px;
      line-height: 16px;
      color: var(--color-grey-70);
      &:not(:last-of-type){
        margin-right: 8px;
      }
      &.date-selected{
        color: var(--color-black-light);
      }
    }
  }

  textarea{
    background: var(--color-grey-10);
    border: 1px solid var(--color-grey-20);
    border-radius: 4px;
    padding: 8px 16px;
    margin-bottom: 16px;
  }

  .MuiTabs-root{
    margin-bottom: 16px;
    border-bottom: 1px solid var(--color-grey-20);
    .MuiTab-root{
      min-width: 0;
      text-transform: none;
      font-family: 'IBMPlexSansMedium', sans-serif;;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: var(--color-grey-70);
      padding: 0 0 16px;
      opacity: 1;
      transition: .3s;
      min-height: 0;
      &:hover,
      &.Mui-selected{
        color: var(--color-black-light);
      }
      &:first-of-type,
      &:nth-of-type(2) {
        margin-right: 16px;
      }
    }
    .MuiTabs-indicator{
      background: var(--color-black-light);
      border-radius: 4px 4px 0 0;
      height: 4px;
    }
  }

  .activity{
    display: flex;
    flex-direction: column-reverse;
    .activity-item{
      display: flex;
      margin-bottom: 32px;
      position: relative;
      padding-left: 40px;
      font-size: 14px;
      line-height: 16px;
      color: var(--color-black-light);
      &::before{
        content: '';
        width: 5px;
        height: 5px;
        position: absolute;
        left: 2px;
        top: 50%;
        transform: translateY(-50%);
        background-color: var(--color-grey-40);
        border-radius: 50%;
      }
      &:not(:first-of-type)::after {
        content: '';
        position: absolute;
        top: 100%;
        height: 32px;
        border-left: 1px solid var(--color-grey-30);
        left: 4px;
      }
      &:last-of-type::before {
        left: 0;
        width: 9px;
        height: 9px;
        background-color: var(--color-black-light);
      }
      .activity-type{
        font-family: 'IBMPlexSansMedium', sans-serif;;
        color: var(--color-grey-70);
        width: 35%;
      }
      .activity-user{
        width: 45%;
      }
      .activity-date{
        width: 25%;
      }
    }
  }

  .comment{
    margin-bottom: 20px;
    position: relative;
    .comment-icon{
      position: absolute;
      left: -50px;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      overflow: hidden;
      background-color: var(--color-additional-1);
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: 'IBMPlexSansMedium', sans-serif;;
      font-size: 16px;
      line-height: 24px;
      color: var(--color-black-light);
    }
    .comment-header{
      font-family: 'IBMPlexSansMedium', sans-serif;;
      font-size: 14px;
      line-height: 16px;
      color: var(--color-black-light);
      margin-bottom: 8px;
      span{
        font-family: 'IBMPlexSansRegular', sans-serif;
        color: var(--color-grey-70);
        margin-left: 8px;
      }
    }
    .comment-body{
      p{
        font-size: 14px;
        line-height: 16px;
      }
      a{
        display: inline-block;
      }
    }
  }
  .comment-area{
    position: relative;
    font-size: 14px;
    line-height: 16px;
    color: var(--color-black-light);
    .comment-btn{
      transform: scale(-1, 1);
      position: absolute;
      right: 10px;
      bottom: 2px;
      cursor: pointer;
      opacity: .5;
      transition: .3s;
      &:hover{
        opacity: 1;
      }
    }
  }
  h5{
    width: 100%;
    font-family: 'IBMPlexSansMedium', sans-serif;;
    font-size: 16px;
    line-height: 24px;
    color: var(--color-black-light);
    margin-bottom: 8px;
  }
  .modal-file{
    border: none;
  }
}