@import "../../../assets/styles/mixins";

.table-header-component {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;

  .table-extra-filters {
    flex-grow: 1;
    margin-left: 10px;
    margin-right: 5px;
    display: flex;
    justify-content: flex-end;
  }

  .table-header-btns{
    display: flex;
    gap: 16px;
  }

  .settings-modal {
    .row{
      display: flex;
      justify-content: space-between;
    }
    .drag-and-drop-component {
      max-height: 40vh;
      overflow-y: scroll;
    }
  }

  .MuiFormControlLabel-root{
    display: flex;
    flex-direction: row-reverse;
    margin: 0 0 0 16px;
    .MuiTypography-body1{
      font-size: 12px;
      line-height: 16px;
      font-family: 'IBMPlexSansMedium', sans-serif;
      color: var(--color-black-light);
    }
  }
  .export-btn{
    text-decoration: none;
    button{
      height: 100%;
    }
  }
}

.table-search {
  position: relative;
  justify-content: flex-start;
  width: auto;

  img.search {
    position: absolute;
    left: 10px;
    width: 24px;
    height: 24px;
    top: 8px;
  }

  img.close {
    position: absolute;
    visibility: hidden;
    right: 0;
    height: 42px;
    width: 42px;
    padding: 8px;

    &.active {
      visibility: visible;
    }

    &:hover {
      cursor: pointer;
      background: rgba(#612551, .1);
    }
  }

  input {
    padding: 8px 40px;
    width: 320px;
    font-size: 14px;
    line-height: 24px;
    color: var(--color-grey-40);
    border: 1px solid var(--color-grey-20);
    border-radius: 4px;
    background: var(--color-grey-10);

    @include placeholder {
      color: var(--color-grey-40);
    }

    &.open {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}