.bulk-upload{
  &.modal-content{
    padding: 0;
  }
  .modal-body{
    width: 100%;
    //transition: width .3s;
    overflow-x: auto;
    padding: 20px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: calc(100% - 64px);
    h2{
      font-size: 32px;
      line-height: 32px;
      margin-bottom: 40px;
    }
    p.upload-caption{
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 16px;
    }
    .modal-body-img{
      width: 120px;
      height: 120px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--color-grey-10);
      flex:  0 0 auto;
      margin-bottom: 40px;
      svg{
        width: 40px;
        height: 40px;
      }
      path{
        fill: var(--color-grey-70)
      }
      &.fileUploaded{
        background: rgba(11, 166, 45, 0.1);
        path{
          fill: #0BA62D
        }
      }
    }
    .file-options{
      display: flex;
      align-items: center;
      margin-top: 16px;
      justify-content: center;
      .MuiButton-root{
        background-color: transparent;
        color: var(--color-primary);
        font-family: "IBMPlexSansRegular", sans-serif;
      }
      .file-options-separator{
        color: var(--color-grey-40);
      }
      .parse-link{
        color: var(--color-primary);
        font-size: 16px;
        line-height: 24px;
        font-family: 'IBMPlexSansRegular', sans-serif;
        cursor: pointer;
        padding: 8px 16px;
      }
      & + .button{
        margin: 10px auto 0;
      }
    }
    .parse-data-caption{
      font-size: 16px;
      line-height: 24px;
      color: var(--color-grey-70);
      margin-bottom: 24px;
      margin-top: -24px;
    }
    .files-caption{
      font-size: 10px;
      line-height: 14px;
      color: var(--color-grey-70);
      margin-bottom: 16px;
      text-transform: uppercase;
      margin-top: 10px;
      text-align: center;
    }
    .files{
      display: flex;
      justify-content: space-between;
    }
    .file-preview{
      box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.15), -1px 0px 2px rgba(3, 3, 3, 0.1);
      border-radius: 4px;
      padding: 8px;
      display: flex;
      font-family: 'IBMPlexSansMedium', sans-serif;;
      font-size: 12px;
      line-height: 16px;
      color: var(--color-black-light);
      cursor: pointer;
      .file-preview-img{
        width: 40px;
        height: 40px;
        margin-right: 16px;
      }
      .file-preview-info{
        font-size: 12px;
        line-height: 16px;
        font-family: 'IBMPlexSansMedium', sans-serif;
        margin-right: 10px;
        p{
          font-size: 12px;
          line-height: 16px;
          margin: 4px 0 0;
          font-family: "IBMPlexSansRegular", sans-serif;
          color: var(--color-grey-70);
        }
      }
      span, label{
        cursor: pointer;
        opacity: .5;
        transition: .3s;
        margin: 0 5px 0 auto;
        display: flex;
        &:hover{
          opacity: 1;
        }
      }
    }
    .modal-body-bottom{
      margin-top: auto;
      font-size: 14px;
      line-height: 16px;
      p{
        color: var(--color-grey-70);
        display: flex;
        align-items: center;
        margin: 24px 0 0;
        svg{
          margin-right: 8px;
        }
        path{
          stroke: var(--color-grey-70);
        }
      }
      a{
        color: var(--color-primary);
        margin-left: 8px;
        &:hover{
          text-decoration: underline;
        }
      }
    }
    .upload-summary{
      display: flex;
      align-self: start;
      flex-direction: column;
      .summary{
        display: flex;
        justify-content: flex-start;
        // position: relative;
        margin-bottom: 16px;
        .summary-item{
          border-radius: 4px;
          border: 1px solid var(--color-grey-30);
          min-width: 200px;
          &:not(:last-of-type){
            margin-right: 24px;
          }
          .summary-item-top{
            padding: 16px;
            border-bottom: 1px solid var(--color-grey-30);
            h5{
              font-size: 16px;
              line-height: 24px;
              margin-bottom: 8px;
            }
            h3{
              font-size: 32px;
              line-height: 32px;
            }
            .error {
              color: var(--color-waiting);
            }
          }
        }
      }
    }
  }
}