.changelog {
  display: flex;
  flex-direction: column;

  &__item {
    display: flex;
    &__left {
      margin: 0 11px 0 11px;
      display: flex;
      flex-direction: column;
      align-items: center;
      &:not(.last)::after {
        content: "";
        height: 100%;
        width: 2px;
        background-color: #ccc;
      }
      svg {
        // width: 20px;
        flex: none;
        border: 2px solid #fff;
        // border-radius: 50%;
      }
    }
    &__right {
      padding: 0 0 10px 0;
      .title {
        font-size: 14px;
      }
      span {
        font-size: 12px;
        color: #847577;
      }
    }
    &:last-of-type{
      .changelog__item__left{
        &::after{
          display: none;
        }
      }
    }
  }
}