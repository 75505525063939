.uploadDND{
  &.simple {
    width: 100%;
  }
  .uploadDND-form{
    position: relative;
    height: 88px;
    border: 1px dashed var(--color-grey-30);
    border-radius: 4px;
    margin-bottom: 24px;
  }
  .uploadDND-wrapper{
    width: 100%;
    height: 100%;
    text-align: center;
    line-height: 170px;
    color: black;
    position: absolute;
    display: flex;
    input{
      position: absolute;
      margin: 0;
      padding: 0;
      width: 100%;
      height: 100%;
      outline: none;
      opacity: 0;
    }
    .uploadDND-description{
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 24px;
      span{
        width: 1px;
        background-color: var(--color-grey-30);
        height: 100%;
      }
      .uploadDND-caption{
        font-size: 14px;
        line-height: 16px;
        color: var(--color-grey-40);
        font-family: 'IBMPlexSansMedium', sans-serif;
        display: flex;
        align-items: center;
        svg{
          margin-right: 8px;
          path{
            fill: var(--color-grey-40);
          }
        }
      }
    }
  }
  .uploadDND-preview{
    box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.15), -1px 0px 2px rgba(3, 3, 3, 0.1);
    border-radius: 4px;
    padding: 16px 48px;
    margin-bottom: 24px;
    .file-preview{
      position: relative;
      flex-wrap: wrap;
      justify-content: space-between;
      .file-preview-info{
        width: 70%;
      }
      .select-field{
        max-width: 100px;
      }
      .input-field{
        max-width: none;
        margin-top: 8px;
      }
      .file-icon{
        position: absolute;
        left: -32px;
        top: 0;
        &.warning{
          svg{
            width: 24px;
            height: 24px;
            path{
              fill: var(--color-pending);
            }
          }
        }
      }
      .file-remove{
        position: absolute;
        right: -32px;
        top: 0;
        cursor: pointer;
        opacity: .5;
        transition: .3s;
        display: flex;
        &:hover {
          opacity: 1;
        }
      }
    }
  }
  h5.warning{
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 14px;
    color: var(--color-error);
  }
}