.ticket{
  border: 1px solid var(--color-black-light);
  box-sizing: border-box;
  border-radius: 4px;
  padding: 16px;
  margin-bottom: 8px;
  &.reviewed{
    border-color: var(--color-grey-20);
  }
  .ticket-header{
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    overflow: hidden;
    .ticket-main-info{
      display: flex;
      svg{
        flex: 0 0 auto;
        width: 16px;
        height: 16px;
        margin-right: 8px;
      }
      p{
        font-family: 'IBMPlexSansMedium', sans-serif;;
        font-size: 12px;
        line-height: 16px;
        color: var(--color-additional-2);
        margin-right: 8px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
        //&:last-of-type{
        //  color: var(--color-primary);
        //}
      }
    }
    time{
      font-size: 12px;
      line-height: 16px;
      color: #847577;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
      flex: 0 0 auto;
    }
  }
  .ticket-description{
    font-style: normal;
    font-family: 'IBMPlexSansMedium', sans-serif;;
    font-size: 14px;
    line-height: 16px;
    color: var(--color-black-light);
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    overflow: hidden;
    margin-bottom: 24px;
  }
  .ticket-footer{
    display: flex;
    align-items: center;
    font-size: 12px;
    line-height: 16px;
    & > div{
      display: flex;
      align-items: center;
    }
    p {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
    }
    .ticket-person{
      margin-right: 16px;
      color: var(--color-black-light);
    }
    .ticket-comments{
      margin-right: 16px;
      color: var(--color-black-light);
      flex: 0 0 auto;
      &.noComment{
        opacity: .5;
      }
    }
    .ticket-actions{
      margin-left: auto;
      .MuiButton-root{
        min-width: 0;
        border-radius: 50%;
      }
    }
    div:not(:first-of-type){
      flex: 0 0 auto;
    }
    div:not(:last-of-type){
      img,
      svg{
        width: 16px;
        height: 16px;
        margin-right: 4px;
      }
    }
  }
  &.active{
    background-color: var(--color-pending-approval);
    border-color: transparent;
    box-shadow: 3px 4px 4px rgba(0, 0, 0, 0.15), -1px 0px 2px rgba(3, 3, 3, 0.1);
  }
}