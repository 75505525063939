.Project{
  .projectInfo{
    .MuiTabs-root{
      padding: 16px;
    }
    .MuiTabs-flexContainer{
      gap: 32px;
      .MuiTab-root{
        user-select: text;
        padding: 0;
        display: flex;
        cursor: text;
        align-items: flex-start;
        color: inherit;
        letter-spacing: initial;
        text-transform: none;
        font-size: inherit;
        font-family: inherit;
        min-height: 0;
      }
    }
    .MuiTabs-scrollButtons{
      border-radius: 24px;
      background-color: var(--color-grey-10);
      transition: background-color .3s;
      &:hover{
        background-color: var(--color-grey-20);
      }
      &.Mui-disabled{
        width: 0;
      }
    }
    p{
      color: var(--color-grey-70);
      font-family: 'IBMPlexSansMedium', sans-serif;
      font-size: 12px;
      line-height: 16px;
      text-transform: uppercase;
      margin-bottom: 8px;
    }
    h5{
      font-size: 14px;
      line-height: 16px;
    }
  }
  .projectStats {
    grid-gap: 25px;
    display: grid;
    gap: 25px;
    grid-template-columns: repeat(5, 1fr);
    justify-content: space-between;
    .box__main{
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 100%;
    }
    h2 {
      font-size: 48px;
      line-height: 48px;
      margin: auto;
    }
    span{
      font-size: 14px;
      line-height: 16px;
      font-family: "IBMPlexSansMedium", sans-serif;
    }
    canvas {
      height: 98px !important;
      width: 98px !important;
      margin-top: 4px;
    }
  }
  .tabs .MuiTabs-root{
    margin-bottom: 16px;
  }
}
