.dashboard {

  .overview {
    margin-bottom: 16px;
    &__items{
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      align-items: center;
      justify-content: space-between;
      gap: 25px;
    }
    &__item {
      border-radius: 16px;
      border: 1px solid var(--color-grey-30);
      display: flex;
      padding: 24px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 8px;
      font-family: IBMPlexSansMedium, sans-serif;
      h5{
        color: var(--color-black-light);
        font-size: 32px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px;

        &.pending {
          color: var(--color-pending);
        }
      }
      span{
        color: var(--color-grey-70);
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
      }
    }
  }

  .dashboard-info {
    display: flex;
    justify-content: space-between;
    gap: 24px;

    .dashboard-main{
      width: 100%;
      min-width: 0;
    }
    .dashboard-aside {
      width: 330px;
      flex: none;
      .box{
        li{
          display: flex;
          justify-content: space-between;
          h5{
            width: 50%;
            color: var(--color-black-light);
            text-overflow: ellipsis;
            font-family: 'IBMPlexSansMedium', sans-serif;
            font-size: 14px;
            line-height: 16px;
          }
          span{
            color: var(--color-black-light);
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
          }
          &:not(:last-of-type){
            margin-bottom: 16px;
          }
          .lines{
            display: flex;
            width: 55%;
            gap: 1px;
            span{
              white-space: nowrap;
            }
          }
          .line{
            width: 50%;
            .filled{
              height: 16px;
              background-color: var(--color-primary);
              position: relative;
              span{
                position: absolute;
                left: 100%;
                top: 50%;
                transform: translateY(-50%);
                padding-left: 4px;
                font-size: 12px;
                line-height: 16px;
                color: var(--color-black-light);
              }
            }
            &--negative{
              display: flex;
              flex-direction: row-reverse;
              position: relative;
              &::before{
                content: '';
                display: inline-block;
                width: 1px;
                background-color: var(--color-grey-20);
                position: absolute;
                right: -1px;
                top: -8px;
                bottom: -8px;
              }
              .filled{
                background-color: var(--color-additional-3);
                span{
                  left: auto;
                  right: 100%;
                  padding: 0 4px 0 0;
                }
              }
            }
            &--positive{
              .filled {
                background-color: var(--color-waiting);
              }
            }
          }
        }
      }
      .taskOverdue{
        li h5{
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          overflow: hidden;
          width: 43%;
        }
      }
    }
  }
}