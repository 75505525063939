@import "./variables.scss";
@import "./mixins.scss";
@import "./minireset.scss";
@import "./fonts.scss";
@import "./modal.scss";
@import "./datepicker.scss";
@import "./chart.scss";

body{
  margin: 0;
  font-family: 'IBMPlexSansRegular', sans-serif;
  color: var(--color-black-light);
  @include scrollbar(false, "&");
  .nav-section {
    position: fixed;
    display: flex;
    flex-direction: column;
    width: var(--nav-width);
    height: 100%;
    padding: var(--header-height) 0 16px 0;
    transition: .3s;
    overflow-y: auto;

    @include scrollbar(true, "&");
    &:hover{
      @include scrollbar(false, "&");
    }
    path{
      transition: .3s;
    }

    .nav-menu {
      margin-top: 24px;

      &:first-child {
        margin-top: 0;
      }
    }
    .nav-btn{
      margin-top: auto;
      cursor: pointer;
      padding: 0 16px;
      svg{
        width: 24px;
        height: 24px;
      }
      &:hover{
        path{
          fill: var(--color-primary);
        }
      }
    }
    .link-name{
      transition: 0.3s;
    }
  }
  .app-container {
    margin: 0 auto;
    &.navHide{
      .nav-section{
        width: 60px;
        .link-name{
          opacity: 0;
          transition-delay: 0s;
          pointer-events: none;
        }
      }
      .content-section,{
        width: calc(100% - 60px);
        margin-left: 60px;
      }
    }
    .MuiCircularProgress-root{
      display: flex;
      margin: 50px auto;
    }
    .update-version {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #242526;
      z-index: 1000;
      position: fixed;
      right: 10px;
      bottom: 10px;
      color: #fff;
      border-radius: 5px;
      padding: 20px;
      box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1);
      p {
        margin-right: 60px;
        width: 180px;
      }
      a {
        color: #4599FF;
      }
    }
  }
  .content-section {
    display: flex;
    width: calc(100% - var(--nav-width));
    margin-left: var(--nav-width);
    flex-direction: column;
    transition: .3s;
    padding: calc(var(--header-height) + 24px) 24px 0;
  }
  .MuiTableCell-head{
    font-family: 'IBMPlexSansMedium', sans-serif;
  }
  .MuiTableCell-body{
    font-family: 'IBMPlexSansRegular', sans-serif;
  }
  .MuiTableCell-body, .MuiTableCell-head{
    color: var(--color-black-light);
    letter-spacing: 0;
  }
  .back-btn{
    cursor: pointer;
    transition: .3s;
    display: flex;
    &:hover{
      opacity: .7;
    }
  }
  .pl-40{
    padding-left: 40px;
  }
}
.tabs{
  margin-bottom: 16px;
  .MuiTabs-indicator {
    background-color: var(--color-primary);
    height: 4px;
    border-radius: 4px 4px 0 0;
  }
  .MuiButtonBase-root{
    font-size: 16px;
    line-height: 24px;
    font-family: 'IBMPlexSansMedium', sans-serif;
    color: var(--color-grey-70);
    transition: .3s;
    text-transform: none;
    min-width: 0;
    text-align: center;
    padding: 12px 0 16px;
    opacity: 1;
    min-height: 0;
    &:hover,
    &.Mui-selected{
      color: var(--color-primary);
    }
    &:not(:last-of-type) {
      margin-right: 16px;
    }
  }
}

.file-preview {
  display: flex;
  align-items: center;
  justify-content: space-between;
  &.file-preview--download{
    border: 1px solid var(--color-grey-20);
    border-radius: 4px;
    cursor: pointer;
    padding: 8px 12px;
    transition: .3s;
    &:hover{
      background-color: var(--color-grey-10);
      border-color: var(--color-grey-40);
      .file-upload {
        opacity: 1;
      }
    }
  }

  &:not(:last-of-type) {
    margin-bottom: 16px;
  }

  .file-preview-info{
    font-size: 14px;
    line-height: 16px;
    font-family: 'IBMPlexSansMedium', sans-serif;
    p{
      font-size: 12px;
      line-height: 16px;
      font-family: "IBMPlexSansRegular", sans-serif;
      color: var(--color-grey-70);
    }
  }
  .file-preview-btns{
    display: flex;
    gap: 8px;
  }
  .file-upload {
    opacity: 0;
    transition: .3s;
    svg{
      width: 16px;
      height: 16px;
    }
    path{
      fill: var(--color-grey-70)
    }
    &:hover{
      path{
        fill: var(--color-black-light);
      }
    }
  }
}
.page-title-wrap{
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  .page-title-left{
    display: flex;
    align-items: center;
    gap: 16px;
    path{
      fill: var(--color-grey-70);
    }
    h1{
      margin-bottom: 0;
    }
  }
}
h1.page-title{
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 16px;
}
.select-field{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .MuiInputBase-root{
    border: 1px solid var(--color-grey-20);
    border-radius: 16px;
    .MuiSelect-select{
      padding: 5px 24px 5px 8px;
      font-family: 'IBMPlexSansMedium', sans-serif;;
      font-size: 10px;
      line-height: 14px;
      color: var(--color-black-light);
    }
  }
  .MuiOutlinedInput-notchedOutline{
    border: none;
  }
}
.input-field{
  padding: 12px;
  max-width: 400px;
  width: 100%;
  font-size: 14px;
  line-height: 16px;
  color: var(--color-black-light);
  border: 1px solid var(--color-grey-20);
  border-radius: 4px;
  background: var(--color-grey-10);
  display: block;
  &[readonly]{
    cursor: not-allowed;
  }
  .MuiOutlinedInput-root{
    &:hover{
      .MuiOutlinedInput-notchedOutline{
        border-color: var(--color-grey-20);
      }
    }
    &.Mui-focused fieldset.MuiOutlinedInput-notchedOutline{
      border-color: var(--color-primary-tertiary);
    }
  }
  &:focus{
    outline: 1px solid var(--color-primary-tertiary);
  }
  .MuiInputAdornment-root p{
    font-family: 'IBMPlexSansMedium', sans-serif;
    font-size: 12px;
    line-height: 16px;
    color: var(--color-grey-70);
    text-transform: uppercase;
  }

}

span.status {
  padding: 5px 10px;
  border-radius: 16px;
  font-size: 12px!important;
  line-height: 16px;
  font-family: 'IBMPlexSansMedium', sans-serif;
  &.error{
    color: var(--color-error);
    background-color: var(--color-highlight-4);
  }
  &.default{
    color: var(--color-black-light);
    background-color: var(--color-grey-10);
  }
  &.pending{
    color: var(--color-white);
    background-color: var(--color-additional-2);
  }
  &.success{
    color: var(--color-white);
    background-color: var(--color-additional-3);
  }
  &.in-progress{
    color: var(--color-white);
    background-color: var(--color-primary);
  }
  &.on-hold{
    color: var(--color-white);
    background-color: var(--color-pending);
  }
  &.waiting{
    color: #FBDB6C;
    background-color: var(--color-primary);
  }
  &.site{
    color: var(--color-primary);
    background-color: var(--color-highlight-1);
  }
  &.accepted{
    color: white;
    background-color: var(--color-primary);
  }
}

.edited{
  outline: 1px solid var(--color-additional-3);
  outline-offset: 2px;
}

.info{
  border: 1px solid var(--color-grey-30);
  border-radius: 16px;
  &__header{
    padding: 16px 24px;
    border-bottom: 1px solid var(--color-grey-30);
    display: flex;
    align-items: center;
    gap: 16px;
    svg{
      display: flex;
      width: 24px;
      height: 24px;
      path{
        fill: var(--color-black-light);
      }
    }
  }
  &__items{
    padding: 8px 24px 24px;
    li{
      font-family: "IBMPlexSansMedium", sans-serif;
      font-size: 14px;
      line-height: 16px;
      padding: 16px 0;
      display: flex;
      justify-content: space-between;
      & > span{
        font-family: "IBMPlexSansRegular", sans-serif;
        &:empty::after{
          content: 'N/A';
          display: block;
          color: var(--color-grey-70);
        }
      }
      &:not(:last-of-type){
        border-bottom: 1px solid var(--color-grey-20);
      }
    }
  }
  &--full{
    grid-row: span 2;
  }
}
.list-table-page{
  .table-content{
    max-height: calc(100vh - 230px);
  }
}
.arrow-down {
  position: relative;
  top: -2px;
  right: -5px;
  content: "";
  display: inline-block;
  width: 10px;
  height: 10px;
  border-right: 1px solid var(--color-grey-70);
  border-top: 1px solid var(--color-grey-70);
  transform: rotate(135deg);
  margin-right: 5px;
  margin-left: 5px;
}