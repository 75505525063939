.userDetails{
  &__header{
    border-bottom: 1px solid var(--color-grey-30);
    margin-bottom: 24px;
    font-family: "IBMPlexSansMedium", sans-serif;
    h3{
      font-size: 24px;
      line-height: 32px
    }
  }
  .info{
    width: 100%;
    li{
      position: relative;
      .MuiFormControlLabel-root{
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
  .userDetailsInfo{
    display: flex;
    gap: 24px;
  }
}