.siteGeneralInfo{
  &__header{
    padding-bottom: 16px;
    border-bottom: 1px solid var(--color-grey-30);
    margin-bottom: 24px;
    font-family: "IBMPlexSansMedium", sans-serif;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    span{
      font-size: 14px;
      line-height: 16px;
      color: var(--color-grey-70);
      display: inline-block;
    }
    h3{
      font-size: 24px;
      line-height: 32px
    }
    p{
      color: var(--color-primary);
      font-size: 14px;
      line-height: 16px;
      cursor: pointer;
    }
  }
  &__main{

  }
  &__map{
    margin-bottom: 16px;
    position: relative;
    .map-view{
      width: 100%;
      height: 290px;
      border-radius: 16px;
      overflow: hidden;
    }
    .map-info{
      padding: 12px 12px 12px 45px;
      border-radius: 14px;
      position: absolute;
      right: 2px;
      bottom: 2px;
      background-color: var(--color-white);
      h6{
        font-family: 'IBMPlexSansMedium', sans-serif;
        font-size: 16px;
        line-height: 24px;
      }
      svg{
        position: absolute;
        left: 12px;
        top: 12px;
      }
    }
    p{
      display: flex;
      gap: 8px;
      span{
        font-size: 14px;
        line-height: 16px;
        color: var(--color-grey-70);
      }
    }
  }
  &__info{
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
    margin-bottom: 24px;
  }
}