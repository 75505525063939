.ProjectTemplate{
  div[data-rbd-droppable-id] > div:last-of-type{
    .actions__down{
      display: none!important;
    }
  }
}
.projectSequence{
  & > div{
    padding-left: 20px;
    & > div{
      font-family: 'IBMPlexSansMedium', sans-serif;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      gap: 8px;
      position: relative;
      margin-bottom: 10px;
      svg{
        position: absolute;
        left: -24px;
        path{
          fill: var(--color-grey-70)
        }
      }
      &.empty{
        color: var(--color-grey-70);
      }
    }
  }
}
.sequenceCell{
  span{
    display: flex;
    align-items: center;
    gap: 8px;
  }
  svg{
    width: 16px;
    path{
      fill: var(--color-grey-70)
    }
  }
}