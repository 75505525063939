.userChip{
  display: flex;
  height: 32px;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  background-color: var(--color-grey-10);
  transition: .3s;
  border-radius: 16px;
  cursor: pointer;
  p{
    color: var(--color-black-light);
    font-size: 14px;
    font-family: "IBMPlexSansMedium", sans-serif;
    line-height: 16px;
    display: flex;
    align-items: center;
  }
  &:hover,
  &.checked{
    background-color: var(--color-highlight-1);
    p{
      color: var(--color-primary-tertiary);
    }
  }
  &.checked{
    p{
      &::after{
        content: "";
        transition: 0.3s;
        border-left: 1px solid var(--color-primary-tertiary);
        border-bottom: 1px solid var(--color-primary-tertiary);
        width: 10px;
        height: 5px;
        transform: rotate(-45deg);
        margin-left: 7px;
        display: inline-block;
      };
    }
  }
}