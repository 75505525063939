// Blocks
html,
body,
p,
ol,
ul,
li,
dl,
dt,
dd,
blockquote,
figure,
fieldset,
legend,
textarea,
pre,
iframe,
hr,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0; }

// Headings
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: 500;
  font-family: 'IBMPlexSansMedium', sans-serif;
}

// List
ul {
  list-style: none; }

// Form
button,
input,
select,
textarea {
  margin: 0; }

// Box sizing
html {
  box-sizing: border-box;
  font-size: 16px;
}

* {
  &,
  &::before,
  &::after {
    box-sizing: inherit; } }

// Media
img,
video {
  height: auto;
  max-width: 100%; }

// Iframe
iframe {
  border: 0; }

// Table
table {
  border-collapse: collapse;
  border-spacing: 0; }

td,
th {
  padding: 0;
}
a{
  text-decoration: none;
  color: inherit;
}