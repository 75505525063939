@import "../../assets/styles/mixins.scss";

.modal-wrapper {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 99;

  .modal-content {
    position: relative;
    background: var(--color-white);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25), 3px 6px 6px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    padding: 24px;
    max-height: 80vh;
    overflow-y: auto;
    overflow-x: hidden;
    width: 70% !important;
    @include scrollbar(false, "&");
    &.small-width {
      width: 30% !important;
    }
    &.medium-width {
      width: 600px!important;
    }
    &.full-width {
      width: 100% !important;
      height: 100vh;
      max-height: 100vh;

      & > .close-icon {
        padding-top: 15px;
        position: fixed;
      }

      .modal-header {
        position: sticky;
        top: 0;
        z-index: 10;
        background-color: var(--color-grey-10);
        padding: 0 24px;
        height: 56px;
        font-size: 16px;
        line-height: 24px;
        border: none;
        margin: 0;
        display: flex;
        align-items: center;
        h5 {
          margin-right: 24px;
        }
        .modal-header-btns {
          display: flex;
          justify-content: space-between;
          margin-right: 25px;
          margin-left: auto;
          .button {
            margin-right: 16px;
          }
        }
      }
    }

    .modal-body {
      padding: 24px;
    }

    .close-icon {
      display: flex;
      padding: 20px 24px;
      position: absolute;
      width: 72px;
      right: 0;
      top: 0;
      font-size: 34px;
      line-height: 24px;
      color: var(--color-black-light);
      user-select: none;
      pointer-events: all;
      z-index: 100;
      transition: all 0.2s ease-out;

      &:hover {
        cursor: pointer;
        font-size: 44px;
      }
    }
    h3 {
      font-size: 24px;
      line-height: 26px;
      margin-bottom: 24px;
    }
  }
  .modal-buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 10px;
    position: relative;
    .checkbox {
      margin: 0 auto 0 0;
      align-items: center;
      span {
        font-size: 12px;
        line-height: 16px;
        font-family: "IBMPlexSansMedium", sans-serif;
        color: var(--color-black-light);
        padding-left: 16px;
      }
    }
    .button:not(:first-of-type) {
      margin-left: 16px;
    }
  }
  &.ConfirmModal{
    .modal-content{
      max-width: 380px;
    }
    h3{
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 8px;
    }
    h4{
      font-size: 14px;
    }
    .modal-body{
      padding: 0;
      p{
        margin-bottom: 16px;
      }
    }
    .modal-buttons{
      margin-top: 32px;
    }
    textarea{
      border: 1px solid var(--color-grey-20);
      box-sizing: border-box;
      border-radius: 4px;
      padding: 12px 16px;
      resize: none;
      width: 100%;
      font-size: 14px;
      line-height: 16px;
      font-family: "IBMPlexSansRegular", sans-serif;
      color: var(--color-black-light);
      margin-bottom: 5px;
      background-color: var(--color-grey-10);
      &::placeholder {
        font-size: 14px;
        line-height: 16px;
        font-family: "IBMPlexSansRegular", sans-serif;
      }
    }
  }
}

.modal-form {
  counter-reset: item;
  .modal-row {
    margin-bottom: 32px;
    position: relative;
    &.d-flex{
      display: flex;
      gap: 16px;
      .modal-field{
        width: 100%;
      }
    }
    &.mb-0 {
      margin-bottom: 0;
    }
    .modal-field {
      h4 {
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 16px;
        font-family: "IBMPlexSansMedium", sans-serif;
      }
      .modal-caption{
        position: absolute;
        top: 100%;
        left: 0;
        font-size: 10px;
        line-height: 14px;
        color: var(--color-grey-70);
        margin-top: 4px;
      }
      .MuiFormGroup-root{
        flex-direction: row;
        justify-content: flex-start;

        .MuiFormControlLabel-root {
          margin-right: 40px;
          margin-bottom: 10px;
        }
      }
      .MuiOutlinedInput-input {
        padding: 14px 16px;
        font-family: "IBMPlexSansRegular", sans-serif;
      }
      .MuiOutlinedInput-notchedOutline {
        //border: 2px solid #aaaaaa;
      }
      &.modal-field-withIcon {
        position: relative;
        margin-bottom: 40px;
        .MuiOutlinedInput-input {
          padding-left: 48px;
        }
        .modal-icon {
          position: absolute;
          left: 16px;
          top: 12px;
          svg {
            width: 24px;
            height: 24px;
          }
        }
      }
      .MuiOutlinedInput-root {
        transition: 0.3s;
        &.Mui-focused {
          background: rgba(79, 1, 139, 0.1);
          border-color: var(--color-tahakom);
        }
      }
      .modal-input{
        padding: 12px;
        width: 400px;
        font-size: 14px;
        line-height: 16px;
        color: var(--color-black-light);
        border: 1px solid var(--color-grey-20);
        border-radius: 4px;
        background: var(--color-grey-10);
        display: block;
        &.w-100{
          width: 100%;
        }
      }
    }
    .MuiSvgIcon-root{
      fill: var(--color-primary);
    }
  }
  .modal-trash {
    margin: auto 0 15px;
    cursor: pointer;
  }
  .modal-index {
    margin: auto 0;
    font-size: 18px;
    line-height: 22px;
    &::before {
      content: counters(item, ".") " ";
      counter-increment: item;
    }
  }
}
.modal-header {
  border-bottom: 1px solid var(--color-black-light);
  margin-bottom: 32px;
}
.modal-footer {
  display: flex;
}
.modal-btns {
  display: flex;
  margin-left: auto;
  .button:not(:last-of-type) {
    margin-right: 16px;
  }
}
.select-btn {
  margin: 0 16px
}