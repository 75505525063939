.content-section {
  display: flex;
  width: calc(100% - 260px);
  margin-left: 260px;
  padding-right: 24px;
  padding-top: 76px;
  flex-direction: column;
  transition: 0.3s;
}

.hide-nav {
  .content-section {
    width: calc(100% - 70px);
    margin-left: 70px;
  }
}

.top-tab {
  margin-bottom: 32px;
  border-bottom: 1px solid #cfd2cd;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0 10px 0;

  .left {
    h1 {
      font-weight: 500;
      font-size: 24px;

      span {
        color: #847577;
        margin-left: 5px;
      }
    }
  }

  .right {
    display: flex;
    .search {
      position: relative;
      justify-content: flex-start;
      margin-right: 24px;
      width: 300px;
      display: flex;
      // height: 64px;
      align-items: center;
      color: var(--color-white);

      img {
        &.hourglass {
          position: absolute;
          left: 11px;
          width: 18px;
          height: 18px;
        }

        &.close {
          position: absolute;
          visibility: hidden;
          right: 0;
          height: 40px;
          width: 40px;
          padding: 12px;

          &.active {
            visibility: visible;
          }

          &:hover {
            cursor: pointer;
            background: rgba(97, 37, 81, 0.1);
          }
        }
      }

      input {
        padding: 8px 40px;
        width: 100%;
        font-size: 14px;
        line-height: 24px;
        color: rgba(97, 37, 81, 0.6);
        border-width: 0;
        border-color: transparent;
        border-radius: 4px;
        background-color: #f4f4f4;

        &::placeholder {
          color: #a6a2a2;
        }

        &.open {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        }
      }

      .dropdown {
        display: flex;
        position: absolute;
        top: calc(64px - 12px);
        height: 160px;
        left: 0;
        right: 0;
        background-color: var(--color-white);
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.33);
        justify-content: center;
        align-items: center;
      }
    }

    .filter-btn {
      display: flex;
      align-items: center;
      padding: 8px;
      border: 1px solid #e5e6e4;
      border-radius: 4px;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: #030303;
      cursor: pointer;
      font-family: "IBMPlexSansMedium", sans-serif;
      margin: 0 5px;

      img {
        vertical-align: middle;
      }

      .filter-text {
        margin-left: 8px;
      }
    }

    .new-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: "IBMPlexSansMedium", sans-serif;
      font-size: 16px;
      color: #fff;
      background-color: #0579df;
      border-radius: 5px;
      border: none;
      padding: 0 20px;
      margin: 0 5px;
      cursor: pointer;

      &:hover {
        background-color: #309af6;
      }
    }

    .toggle-completed {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      font-family: "IBMPlexSansRegular", sans-serif;
      margin-left: 20px;

      &__text {
        margin-right: 5px;
      }
    }
  }
}

.info-item {
  display: flex;
  padding: 8px;
  margin-right: 16px;

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-left: 0;
    margin-right: 0;
  }

  .left-side {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
    height: 32px;
    width: 32px;
    border-radius: 50%;

    img {
      height: 22px;
      width: 22px;
    }
  }

  .right-side {
    h3 {
      font-weight: 600;
      font-size: 32px;
      line-height: 32px;
      margin: 0;
      cursor: pointer;
      font-family: "IBMPlexSansMedium", sans-serif;
    }

    p {
      font-weight: 400;
      font-size: 12px;
      color: #847577;
      margin: 0 0 10px 3px;
      text-transform: capitalize;
    }
  }
}

.color-blue {
  .left-side {
    background-color: rgba(5, 121, 223, 0.1);
  }

  .right-side h3 {
    color: #0579df;
  }
}

.color-orange {
  .left-side {
    background-color: rgba(255, 136, 0, 0.1);
  }

  .right-side h3 {
    color: #f80;
  }
}

.color-green {
  .left-side {
    background-color: rgba(70, 167, 103, 0.1);
  }

  .right-side h3 {
    color: #46a767;
  }
}

.color-blue {
  .left-side {
    background-color: rgba(5, 121, 223, 0.1);
  }

  .right-side h3 {
    color: #0579df;
  }
}

.color-red {
  .left-side {
    background-color: rgba(249, 80, 102, 0.1);
  }

  .right-side h3 {
    color: #f95066;
  }
}

.color-violet {
  .left-side {
    background-color: rgba(170, 62, 152, 0.1);
  }

  .right-side h3 {
    color: #aa3e98;
  }
}

.color-aqua {
  .left-side {
    background-color: rgba(30, 186, 196, 0.1);
  }

  .right-side h3 {
    color: #1ebac4;
  }
}
