:root {
  --color-white: #fff;
  --color-black: #000;
  --color-black-light: #030303;
  --color-primary: #0579df;
  --color-primary-tertiary: #024682;
  --color-tahakom-opacity: rgba(97, 37, 81, 0.1);
  --color-tahakom-10: #f4e8f2;
  --color-tahakom-80: #815174;
  --color-ongoing: #0579df;
  --color-ongoing-active: rgba(5, 121, 223, 0.8);
  --color-additional-1: #1ebac4;
  --color-additional-2: #aa3e98;
  --color-additional-3: #46a767;
  --color-waiting: #f32641;
  --color-grey-10: #f4f4f4;
  --color-grey-active: rgba(244, 244, 244, 0.8);
  --color-grey-20: #e5e6e4;
  --color-grey-30: #cfd2cd;
  --color-grey-40: #a6a2a2;
  --color-grey-70: #847577;
  --color-pending-approval: #fbe646;
  --color-pending: #ff8800;
  --color-selected: #DDEFFF;
  --color-highlight-1: #E3F2FF;
  --color-highlight-2: #D8F8DC;
  --color-highlight-3: #fffbdb;
  --color-highlight-4: #F8E2DD;
  --color-highlight-5: #F7EFDA;

  --header-height: 64px;
  --nav-width: 236px;

}