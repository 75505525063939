.DateCalendarField{
  display: flex;
  justify-content: center;
  align-items: center;
  .dateCalendarField__wrap{
    background-color: var(--color-white);
  }
  .dateRangePicker {
    font-family: 'IBMPlexSansMedium', sans-serif;
    padding: 20px;
    & > .date-picker-input{
      display: none;
    }
    .dialog-header{
      padding: 0;
      margin-bottom: 10px;
      .date-picker-input{
        width: 65%;
      }
    }
    .dialog-date-picker {
      box-shadow: none;
      position: static;
      transform: scale(1);
      opacity: 1;
      margin: 0;
      width: 600px;
      animation: none;
      .month-calendar{
        margin-right: 40px;
      }
      .calendar-wrapper{
        margin: 12px 0 8px 0;
        min-height: auto;
      }
      .dialog-content{
        border: none;
      }
      .dialog-footer{
        display: none;
      }
    }
    .day{
      border-radius: 4px;
      font-size: 14px;
      line-height: 16px;
      width: 40px;
      height: 40px;
      margin: 0;
      color: var(--color-black-light);
      &::after{
        width: 40px;
        height: 40px;
      }
      &.selected::after,
      &.selected.hovered::after{
        background-color: var(--color-primary)!important;
      }
      &.selected::after{
        border-radius: 4px 0 0 4px;
      }
      &.selected.end::after{
        border-radius: 0 4px 4px 0;
      }
      &.highlight{
        background-color: transparent;
        &::before{
          display: none;
        }
        .text-day{
          color: var(--color-additional-2);
        }
      }
      &.hovered{
        background-color: var(--color-black-light);
        color: var(--color-white);
        border-radius: 0;
      }
      &.selected{
        background: var(--color-grey-10);
        border-radius: 4px;
        color: var(--color-black-light);
      }
      &:hover::after{
        border: none;
        background-color: var(--color-primary);
        border-radius: 4px;
      }
      &.disabled{
        color: var(--color-grey-40);
      }
    }
    .calendar-flippers{
      bottom: auto;
      left: 25px;
      right: 25px;
      .flipper-button{
        height: 25px;
        width: 25px;
        box-shadow: none;
        path{
          fill: var(--color-grey-70)
        }
      }
    }
  }
  .calendar-btns{
    display: flex;
    justify-content: flex-end;
    gap: 8px;
    padding: 0 16px 24px;
  }
}