.modal-radio{
  display: flex;
  label{
    display: flex;
    &:not(:last-of-type){
      margin-right: 4px;
    }
  }
  input{
    appearance: none;
    -webkit-appearance: none;
  }
  span{
    padding: 4px 8px;
    border: 1px solid var(--color-grey-20);
    transition: .3s;
    cursor: pointer;
    border-radius: 16px;
    font-family: 'IBMPlexSansMedium', sans-serif;;
    font-size: 10px;
    line-height: 14px;
    color: var(--color-black-light);
    display: flex;
    align-items: center;
    svg{
      margin-right: 4px;
      path{
        transition: .3s;
      }
    }
  }
  span:hover,
  input:checked + span{
    color: var(--color-white);
    background-color: var(--color-black-light);
    border-color: transparent;
    path{
      fill: var(--color-white);
    }
  }
}
.modal-caption{
  width: 100%;
  padding: 8px 0 8px 92px;
  font-size: 10px;
  line-height: 14px;
  color: var(--color-grey-70);
}