.chart,
.box{
  border-radius: 16px;
  border: 1px solid var(--color-grey-30);
  margin-bottom: 24px;
  &__header,
  &__header {
    padding: 16px 24px;
    border-bottom: 1px solid var(--color-grey-30);
    display: flex;
    justify-content: space-between;
    align-items: center;
    h5{
      display: flex;
      align-items: center;
      gap: 8px;
    }
    .MuiFormControlLabel-root{
      gap: 8px;
    }
    .MuiFormControlLabel-label{
      font-family: var(--font-primary-medium);
      font-size: 14px;
      line-height: 16px;
      color: var(--color-black-light);
    }
  }
  &__main {
    padding: 24px;
  }
  &__legends{
    display: flex;
    align-items: center;
    li{
      display: flex;
      align-items: center;
      gap: 8px;
      &:not(:empty){
        margin-right: 24px;
      }
    }
    .legends__color{
      display: flex;
      height: 8px;
      width: 25px;
      &.line{
        align-items: center;
        span{
          border: 1px dashed;
          height: 1px;
          background: none!important;
        }
      }
    }
    p{
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      color: var(--color-black-light);
      span{
        color: var(--color-grey-70);
      }
    }
  }
  &__row{
    display: flex;
    align-items: center;
    &:not(:last-child){
      margin-bottom: 16px;
    }
  }
  &__rowTitle{
    width: 15%;
    flex: none;
    font-family: var(--font-primary-medium);
    font-size: 14px;
    line-height: 16px
  }
  &__tabs{
    display: flex;
    align-items: center;
  }
  &__tab{
    padding: 4px 12px;
    font-size: 16px;
    line-height: 24px;
    font-family: var(--font-primary-medium);
    color: var(--color-black-light);
    border: 1px solid var(--color-grey-30);
    background-color: var(--color-white);
    border-radius: 4px 0 0 4px;
    transition: .3s;
    cursor: pointer;
    &:last-of-type{
      border-radius: 0 4px 4px 0;
    }
    &.active,
    &:hover{
      color: var(--color-primary);
      border-color: var(--color-primary);
      background-color: var(--color-highlight-1);
    }
  }
}