@import "../../../assets/styles/mixins";

.dropdown {
  display: flex;
  position: relative;
  align-items: center;

  @include scrollbar();

  .dropdown-content {
    display: none;
    min-width: 260px;
    position: absolute;
    top: 42px;
    z-index: 1500;
    background: var(--color-white);
    border: 1px solid var(--color-grey-20);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    padding: 26px;
    flex-direction: column;
    max-height: 460px;
    overflow-y: auto;
    white-space: nowrap;

    &.active {
      display: flex;
    }

    &.right {
      right: 0;
    }
  }
}
