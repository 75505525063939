@import "../../../assets/styles/mixins";

.table-component {
  display: block;
  position: relative;
  width: 100%;

  @include scrollbar(true);

  &:hover {
    @include scrollbar(false);
  }

  .loading-screen {
    display: none;
    position: absolute;
    top: 36px;
    left: 0;
    right: 0;
    bottom: 0;
    min-height: 360px;
    font-size: 30px;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, .3);
    color: var(--color-primary);
  }

  .table-inner {
    display: flex;
    width: 100%;
    flex-direction: column;

    .table-buttons {
      display: flex;
      width: 100%;
      justify-content: flex-start;
      align-items: center;
      flex-direction: row;

      button {
        margin-right: 8px;
        border-radius: 28px;
        padding: 8px 16px;

        &:hover {
          border: 1px solid rgba(0, 0, 0, .1);
          opacity: .8;
        }

        span {
          font-size: 12px;
          padding: 0;
          line-height: 14px;
        }

        &:nth-child(1) {
          background: var(--color-primary);
          span { color: var(--color-white); }
        }

        &:nth-child(3) {
          span { color: var(--color-error); }
        }
      }
    }

    .table-content {
      display: flex;
      position: relative;
      flex-direction: column;
      margin-top: 24px;

      .table-group-container {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        user-select: none;

        &.opened {
          > .image-container {
            background: var(--color-grey-10) !important;
          }

          > h1.group-title {
            color: var(--color-grey-70);
          }
        }

        h1.group-title {
          display: flex;
          flex-direction: row;
          font-size: 14px;
          line-height: 32px;
          color: var(--color-pending-approval);

          &:hover {
            cursor: pointer;
            color: var(--color-primary);
          }

          .image-container {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 32px;
            height: 32px;
            margin-right: 8px;
            background: rgba(var(--color-pending-approval), .1);
            border-radius: 50%;

            img {
              width: 14px;
              height: 14px;
            }
          }
        }
      }

      .table-group {
        display: flex;
        flex-direction: row;
        overflow: hidden;
        position: relative;

        &.loading {
          .loading-screen {
            display: flex;
            z-index: 3000;
          }

          .table-scroller {
            overflow-x: hidden;
          }
        }
      }

      .table-scroller {
        display: flex;
        overflow-x: auto;
        width: 100%;

        .table-scroller-wrapper{
          width: 100%;
        }
      }

      .table-fixed-content {
        position: sticky;
        z-index: 2;
        background-color: var(--color-white);
        display: flex;

        .MuiAccordionSummary-root{
          //background-color: transparent;
          height: 36px;
        }
        .table-column .table-cell{
          border-bottom: none;
        }
      }

      label.checkbox {
        margin-bottom: 0;
      }

      .table-column {
        display: flex;
        position: relative;
        flex-direction: row;
        min-height: 36px;
        min-width: 100%;
        width: min-content;
        transition: .3s;
        &.table-column-grouped-fixed {
          height: auto;
          .table-cell{
            height: 36px;
          }
          .MuiPaper-root:not(:first-of-type) {
            .table-cell {
              border-left: none;
            }
          }
        }

        &:last-child {
          border-bottom: 0;
        }

        &.selected {
          .table-cell {
            background-color: rgba(var(--color-primary), .1);
          }
        }

        span {
          font-size: 14px;
        }

        &.table-header {
          // margin-bottom: 8px;
          border-bottom: 0 !important;
          padding-bottom: 16px;

          .table-cell {
            border-right: 1px solid var(--color-grey-20);
            justify-content: space-between;
            border-bottom: 0;
            padding: 0 8px;

            &.small, &:last-child {
              border-right: 0;
            }

            &.invisible {
              visibility: hidden;
            }

            img {
              width: 14px;
              margin-left: auto;

              &:hover {
                cursor: pointer;
                opacity: .5;
              }
            }

            span {
              font-size: 10px;
              line-height: 14px;
              color: var(--color-grey-70);
              text-transform: uppercase;
              user-select: none;

              &:hover {
                cursor: pointer;
                text-decoration: underline;
              }
            }
          }
          .actions{
            opacity: 0;
            flex: 0;
          }
        }

        &.table-filter-bar {
          margin-bottom: 10px;

          .table-cell {
            border-bottom: 0;
          }

          .table-fixed-filter {
            position: sticky;
            z-index: 2;
            background-color: var(--color-white);
            display: flex;
          }

          .invisible {
            visibility: hidden;
          }

          img.search {
            display: none;
          }

          img.close {
            height: 36px;
            width: 20px;
            padding: 0;

            &:hover {
              opacity: .3;
              background: transparent;
            }
          }

          input {
            width: 100%;
            padding: 8px;
            line-height: 14px;
          }

          &.table-filter-bar-checkbox{
            padding-left: 40px;
          }
        }

        .table-cell.error-field {
          background-color: #FFEAEA;
        }

        .table-cell {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          min-width: 140px;
          width: auto;
          flex: 1;
          padding: 4px 8px;
          overflow: hidden;
          border-bottom: 1px solid var(--color-grey-20);
          position: relative;

          span {
            font-size: 13px;
            word-break: break-word;
            //position: relative;
            &.bold{
              font-family: 'IBMPlexSansMedium', sans-serif;
            }
          }
          &.simple-cell span{
            font-family: "IBMPlexSansRegular", sans-serif;
          }

          &:last-child {
            margin-right: 0;
          }

          &.tiny {
            flex: 0;
            min-width: 50px;
          }

          &.small {
            flex: 0;
            min-width: 62px;
          }

          .checkbox {
            align-items: center;
          }

          .table-search{
            width: 100%;
          }
        }
        &.hasHover{
          cursor: pointer;
          .actions{
            opacity: 0;
            pointer-events: none;
            position: sticky;
            right: 0;
            padding: 0;
            flex: 0;
            z-index: 10;
            overflow: visible;
            & > span{
              width: 100%;
              display: flex;
              gap: 8px;
              justify-content: flex-end;
              padding-right: 8px;
              svg{
                width: 16px;
              }
            }
            &__wrap{
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: #E4F2FE;
              path{
                fill: var(--color-grey-70);
              }
              &:hover{
                path{
                  fill: var(--color-black-light)
                }
              }
            }

            &:empty{
              display: none;
            }
          }
          &:hover {
            .table-cell {
              background-color: #E4F2FE;
            }
            .actions{
              opacity: 1;
              pointer-events: auto;
            }
          }
        }
        .table-column-link{
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          bottom: 0;
          z-index: 3;
        }
        .taskName{
          padding-left: 25px;
        }
        span.editable-date-cell{
          background-color: var(--color-grey-10);
          border-radius: 16px;
          display: flex;
          align-items: center;
          padding: 4px 8px;
          font-family: 'IBMPlexSansMedium', sans-serif;
          font-size: 10px;
          line-height: 14px;
          &.active{
            border-color: var(--color-primary);
          }
          &.empty{
            font-family: 'IBMPlexSansRegular', sans-serif;
            font-size: 14px;
            line-height: 16px;
            background-color: transparent;
            color: var(--color-grey-70);
            border-radius: 0;
            position: relative;
            &::after{
              content: '';
              height: 1px;
              position: absolute;
              left: 8px;
              right: 8px;
              bottom: 3px;
              background-color: var(--color-grey-70);
            }
          }
        }
        .editable-field{
          border-radius: 4px;
          input,
          .MuiSelect-select{
            padding: 4px 6px;
            font-size: 13px;
            width: 100%;
            border-color: rgba(0, 0, 0, 0.23);
            border-radius: 4px;
            outline: none;
            line-height: 1.5;
          }
          .MuiSelect-select{
            padding-right: 32px;
          }
          fieldset{
            top: 0;
            legend{
              display: none;
            }
          }
          button{
            padding: 0;
          }
          &:hover{
            input{
              border-color: rgba(0, 0, 0, 0.87);
              &:focus{
                border-color: var(--color-primary);
              }
            }
          }
          svg{
            width: 20px;
            path{
              fill: var(--color-grey-70)
            }
          }
        }
        &.parent-column{
          .taskName{
            position: relative;
            &::before{
              content: '';
              border: solid var(--color-black-light);
              border-width: 0 2px 2px 0;
              display: inline-block;
              padding: 3px;
              transform: rotate(45deg);
              transition: .3s;
              position: absolute;
              left: 5px;
              z-index: 3;
            }
          }
          &.collapsed{
            .taskName{
              &::before{
                transform: rotate(-45deg);;
              }
            }
          }
        }
        &.disabled{
          opacity: .7;
        }
      }
      .MuiCollapse-wrapper .taskName{
        padding-left: 40px;
      }
      &.fixedHeader {
        .table-scroller-wrapper {
          overflow: auto;
        }
        .table-column {
          &.table-header {
            position: sticky;
            z-index: 20;
            background-color: white;
            top: 0;
          }
        }

        // .table-row.first {
        //   margin-top: 36px;
        // }
      }
    }
  }

  .table-footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin: 4px 0;
    height: 42px;

    button {
      height: 42px;

      span {
        padding: 0;
      }
    }

    .dropdown-content {
      min-width: 0;
      top: unset;
      bottom: 50px;
      box-shadow: 0 0 20px rgba(0, 0, 0, .4);
      padding: 16px;

      span {
        padding-left: 8px;
      }

      label.checkbox {
        margin-bottom: 8px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .left-right-switch {
      width: auto;
      border: 0;
      height: 100%;

      span {
        font-size: 14px;
        padding: 0;
        color: var(--color-grey-70);
      }

      img {
        margin: 0;
      }
    }
  }

  .no-records{
    padding: 20px;
  }
  .left {
    left: 0;
    .table-cell {
      width: 130px;
      &:last-child {
        border-right: 1px solid var(--color-grey-40) !important;
      }
    }
  }

  .right {
    right: 0;
    .table-cell {
      width: 130px;
      &:first-child {
        border-left: 1px solid var(--color-grey-40);
      }
    }
  }
}

.table-chip {
  display: flex;
  position: relative;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 4px 16px;
  border-radius: 32px;
  text-transform: capitalize;
}

.actionBtn-cell{
  button{
    background-color: var(--color-grey-10);
    padding: 4px 8px;
    border: none;
    p{
      font-size: 12px;
      line-height: 16px;
      color: var(--color-primary);
    }
  }
}
.progress-cell{
  span{
    width: 100%;
    //height: 4px;
    display: flex;
  }
  &__wrap{
    width: 100%;
    height: 2px;
    //border: 1px solid var(--color-additional-3);
    box-shadow: 0 0 0 1px var(--color-additional-3);
    border-radius: 4px;
    position: relative;
    span{
      display: block;
      height: 2px;
      background-color: var(--color-additional-3);
      position: absolute;
    }
  }
}