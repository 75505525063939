@import "../../assets/styles/mixins";

.modal-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  &.support-modal {
    .modal-content {
      width: 1030px;
    }
  }
  &.assign-modal {
    .modal-content {
      width: 510px;
    }
  }
  .modal-content {
    background-color: var(--color-white);
    width: 500px;
    max-height: 80vh;
    padding: 24px;
    overflow-y: auto;
    outline: none;
    box-sizing: border-box;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25), 3px 6px 6px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    @include scrollbar(false, "&");
    h3 {
      margin-bottom: 18px;
      font-family: "IBMPlexSansMedium", sans-serif;
      font-size: 18px;
      line-height: 24px;
      color: var(--color-black-light);
    }
    h4 {
      font-family: "IBMPlexSansMedium", sans-serif;
      font-size: 16px;
      line-height: 24px;
      color: var(--color-black-light);
      margin-bottom: 8px;
    }
    .modal-row {
      margin-bottom: 24px;
      &.modal-row-flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .modal-field {
          width: 50%;
          &:not(:last-of-type) {
            margin-right: 16px;
          }
        }
        @media (max-width: 767px) {
          flex-direction: column;
          margin-bottom: 0;
          .modal-field {
            width: 100%;
            margin-right: 0;
            margin-bottom: 30px;
          }
        }
      }
    }
    .modal-file {
      //border-top: 1px solid var(--color-grey-20);
      //border-bottom: 1px solid var(--color-grey-20);
      .modal-field {
        padding: 12px 0;
        display: flex;
        flex-wrap: wrap;
      }
      &.row-padding .modal-row-icon {
        top: 15px;
      }
      .MuiButton-text {
        background-color: #4f018b;
        padding: 8px 16px;
        font-size: 16px;
        line-height: 24px;
        font-family: "IBMPlexSansMedium",sans-serif;
        color: #fff;
        text-transform: none;
      }
      &:has(.file-options) .MuiButton-text{
        background-color: transparent;
        color: #4f018b;
        font-family: "IBMPlexSansRegular",sans-serif;
      }
      .file-preview {
        background: var(--color-grey-10);
        box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.15),
          -1px 0px 2px rgba(3, 3, 3, 0.1);
        border-radius: 4px;
        padding: 8px;
        margin-right: 8px;
        display: flex;
        font-family: "IBMPlexSansMedium", sans-serif;
        font-size: 12px;
        line-height: 16px;
        color: var(--color-black-light);
        cursor: pointer;
        span {
          cursor: pointer;
          opacity: 0.5;
          transition: 0.3s;
          margin: 0 5px 0 10px;
          display: flex;
          &:hover {
            opacity: 1;
          }
        }
      }
      input[type="file"] {
        display: none;
      }
    }
    .modal-dynamic-row {
      margin-bottom: 32px;
      label.checkbox {
        margin-bottom: 0;
      }
      .modal-row {
        margin: 16px 0 0;
      }
    }
    .modal-btns {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .button:not(:last-of-type) {
        margin-right: 16px;
      }
    }

    .add-new-field {
      width: 36px;
      height: 24px;
      margin-left: 8px;
      flex: none;
      display: flex;
      align-items: center;
      justify-content: space-between;
      span {
        position: relative;
        cursor: pointer;
        width: 14px;
        height: 14px;
        &:hover {
          opacity: 0.85;
        }
        &::before,
        &::after {
          content: "";
          width: 14px;
          height: 2px;
          background-color: var(--color-primary);
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
        &::after {
          transform: translate(-50%, -50%) rotate(90deg);
        }
        &.remove::after {
          display: none;
        }
        &:only-child {
          margin: 0 auto;
        }
      }
    }
    .row-padding {
      padding-left: 40px;
      position: relative;
      .modal-row-icon {
        position: absolute;
        left: 0;
        top: 0;
        svg {
          width: 24px;
          height: 24px;
          path {
            fill: var(--color-black-light);
          }
        }
      }
    }
  }
}
.modal-field {
  position: relative;
  width: 100%;
  h6{
    margin-bottom: 8px;
  }
  .MuiAutocomplete-root {
    width: 100%;
  }
  .MuiFormControl-root {
    width: 100%;
    margin: 0;
  }
  &.modal-select {
    h6 {
      font-family: "IBMPlexSansMedium", sans-serif;
      font-size: 16px;
      line-height: 24px;
      color: var(--color-black-light);
    }
    .MuiInputBase-root {
      border: 1px solid var(--color-grey-20);
      width: 100%;
      background-color: var(--color-grey-10);
      &.error {
        border-color: var(--color-waiting);
      }
      .MuiSelect-select {
        padding: 12px 24px 12px 16px;
        font-family: "IBMPlexSansRegular", sans-serif;
        font-size: 14px;
        line-height: 16px;
        color: var(--color-black-light);
      }
      &.empty{
        .MuiSelect-select{
          color: var(--color-grey-70);
        }
      }
      &.Mui-focused{
        background-color: #E3F2FF;
        border-color: var(--color-ongoing);
      }
    }
    &:not(:last-of-type) {
      margin-bottom: 24px;
    }
    .MuiOutlinedInput-notchedOutline {
      border: none;
    }
    .MuiTextField-root.error .MuiOutlinedInput-notchedOutline{
      border: 1px solid var(--color-waiting);
    }
    &.modal-select-custom {
      .MuiInputBase-root {
        border: none;
        width: auto;
        background-color: transparent;
        .MuiSelect-select {
          font-size: 14px;
          line-height: 16px;
          padding: 0 24px 0 0;
          font-family: "IBMPlexSansMedium", sans-serif;
        }
        .MuiSelect-icon{
          right: 7px;
        }
      }
    }
    .select-Low {
      .MuiSelect-select {
        color: var(--color-additional-3);
      }
    }
    .select-Medium {
      .MuiSelect-select {
        color: var(--color-pending);
      }
    }
    .select-High,
    .select-Urgent,
    .select-Critical {
      .MuiSelect-select {
        color: var(--color-waiting);
      }
    }
  }
  .validation-message,
  .MuiFormHelperText-root.Mui-error {
    font-family: "IBM Plex Sans", sans-serif;
    position: absolute;
    top: 100%;
    color: var(--color-waiting);
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
    margin: 0;
    letter-spacing: 0;
    &.error {
      overflow: visible;
    }
  }
  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
    padding: 0 40px 0 0 !important;
  }
  .MuiOutlinedInput-notchedOutline {
    border-color: var(--color-grey-10);
    transition: 0.3s;
    border: 1px solid var(--color-grey-20);
    border-radius: 4px;
  }
  .MuiOutlinedInput-input,
  textarea,
  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"],
    .MuiAutocomplete-input:first-child {
    padding: 12px 14px;
    font-size: 14px;
  }
  textarea {
    font: inherit;
    color: currentColor;
    resize: none;
    width: 100%;
    border: none;
    padding: 0;
    display: block;
    &:focus{
      outline: none;
    }
  }
  .MuiInputLabel-outlined {
    transform: translate(14px, 13px) scale(1);
    font-family: "IBMPlexSansMedium", sans-serif;
    font-size: 10px;
    line-height: 14px;
    color: var(--color-black-light);
    background-color: var(--color-white);
  }
  .MuiOutlinedInput-multiline,
  .MuiIconButton-root {
    //padding: 0;
  }
  .MuiAutocomplete-endAdornment {
    display: flex;
    margin-top: 2px;
    .MuiAutocomplete-popupIndicator {
      height: 24px;
      width: 24px;
      border-radius: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: 0.3s;
      svg {
        width: 7px;
        height: 7px;
        border-right: 2px solid var(--color-black-light);
        border-bottom: 2px solid var(--color-black-light);
        transform: rotate(45deg);
        margin-bottom: 5px;
        path {
          display: none;
        }
      }
    }
  }
  .MuiSelect-select {
    line-height: 14px;
    &:focus {
      background-color: transparent;
    }
    & ~ .select-clearBtn{
      position: absolute;
      right: 35px;
      top: 11px;
      cursor: pointer;
      path{
        fill: var(--color-grey-70)
      }
    }
    & ~ svg {
      width: 8px;
      height: 8px;
      border-right: 1px solid var(--color-grey-70);
      border-bottom: 1px solid var(--color-grey-70);
      margin-bottom: 5px;
      top: 50%;
      right: 20px;
      transform: translateY(-75%) rotate(45deg);
      transition: 0.3s;
      position: absolute;
      &.MuiSelect-iconOpen {
        transform: translateY(-50%) rotate(-135deg);
      }
      path {
        display: none;
      }
    }
  }
  .form-icon {
    position: absolute;
    width: 24px;
    height: 24px;
    left: 16px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
    & ~ div {
      .MuiOutlinedInput-input {
        padding-left: 48px;
      }
      .MuiInputBase-root {
        padding: 0 !important;
        .MuiInputBase-input.MuiInputBase-input {
          padding-left: 48px;
        }
      }
    }
  }
  .columnTitle {
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 16px;
    color: var(--color-grey-70);
    span {
      padding: 4px 8px;
      border-radius: 16px;
      font-family: "IBMPlexSansMedium", sans-serif;
      font-size: 12px;
      line-height: 16px;
      color: var(--color-white);
      margin-right: 16px;
      background-color: var(--color-grey-70);
      &.color-0 {
        background-color: var(--color-ongoing);
      }
      &.color-1 {
        background-color: var(--color-additional-1);
      }
      &.color-6 {
        background-color: var(--color-additional-3);
      }
    }
  }
}
.modal-simple-radio {
  margin-bottom: 8px;
  path {
    fill: var(--color-primary);
  }
  .MuiTypography-body1 {
    font-family: "IBMPlexSansMedium", sans-serif;
    font-size: 12px;
    line-height: 16px;
    color: var(--color-black-light);
  }
}

.MuiListItem-root.hiddenOption {
  display: none;
}
.MuiAutocomplete-tag.MuiChip-deletable{
  margin: 8px 4px;
  height: 24px;
  background-color: var(--color-white);
  filter: drop-shadow(1px 2px 2px rgba(0, 0, 0, 0.15)) drop-shadow(-1px 0px 2px rgba(3, 3, 3, 0.1));
  font-size: 10px;
  line-height: 14px;
  font-family: "IBMPlexSansMedium", sans-serif;
  color: var(--color-black-light);
  svg{
    width: 16px;
    height: 16px;
  }
}
.modal-option{
  font-family: "IBMPlexSans", sans-serif;
  width: 100%;
  h5{
    font-size: 16px;
    line-height: 24px;
    color: var(--color-black-light);
    margin-bottom: 8px;
  }
  p{
    font-size: 14px;
    line-height: 16px;
    color: var(--color-grey-70);
    margin-bottom: 8px;
  }
  ul{
    display: flex;
    li{
      width: 33.33%;
      font-size: 14px;
      line-height: 16px;
      color: var(--color-grey-70);
      span{
        color: var(--color-black-light);
      }
    }
  }
}