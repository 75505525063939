.modal.assign-modal .modal-content{
  .modal-radio{
    span{
      background-color: var(--color-grey-10);
      border: none;
      position: relative;
      &.color-0{
        color: var(--color-additional-3)
      }
      &.color-1{
        color: var(--color-pending)
      }
      &.color-2{
        color: var(--color-waiting)
      }
      &::before{
        content: '';
        transition: .3s;
      }
    }
    input:checked + span,
    input:hover + span{
      color: var(--color-white);
      &.color-0{
        background-color: var(--color-additional-3)
      }
      &.color-1{
        background-color: var(--color-pending)
      }
      &.color-2{
        background-color: var(--color-waiting)
      }
      &::before{
        border-left: 1px solid var(--color-white);
        border-bottom: 1px solid var(--color-white);
        width: 10px;
        height: 5px;
        transform: rotate(-45deg);
        margin-right: 7px;
      }
    }
    label:not(:last-of-type){
      margin-right: 8px;
    }
  }
  .modal-caption{
    padding-left: 0;
  }
  .modal-file{
    border-bottom: none;
  }

  .additional-row{
    h4{
      margin-bottom: 24px;
    }
    ::placeholder{
      font-family: 'IBMPlexSansMedium', sans-serif;;
      font-size: 14px;
      line-height: 22px;
      color: var(--color-grey-70);
    }
  }
}