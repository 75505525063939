.agingTable{
  .MuiTableHead-root{
    .MuiTableCell-root{
      &:first-of-type{
        padding: 8px 12px;
        font-size: 10px;
        line-height: 14px;
        font-family: 'IBMPlexSansMedium', sans-serif;
        text-transform: uppercase;
      }
      padding: 0;
      span{
        font-size: 10px;
        line-height: 14px;
        font-family: 'IBMPlexSansMedium', sans-serif;
        text-transform: uppercase;
        padding: 8px 5px;
        color: var(--color-grey-70);
      }
      &:not(:last-of-type) span::after{
        content: '';
        width: 1px;
        height: 10px;
        background-color: var(--color-grey-30);
        display: inline-block;
      }
    }
  }
  .MuiTableBody-root{
    .MuiTableCell-root{
      font-size: 14px;
      line-height: 16px;
      font-family: 'IBMPlexSansRegular', sans-serif;
      color: var(--color-black-light);
      padding: 8px 12px;
      border-bottom: 1px solid var(--color-grey-10);
    }
  }
  .MuiTableCell-root{
    border: none;
    &.MuiTableCell-alignRight{
      flex-direction: row;
    }
    &.classification{
      font-size: 14px;
      line-height: 16px;
      color: var(--color-black-light);
      font-family: 'IBMPlexSansRegular', sans-serif;
    }
  }
  .table-row{
    transition: .3s;
    .MuiTableCell-root{
      padding-top: 25px;
      letter-spacing: initial;
    }
    &.parent-row{
      cursor: pointer;
      .MuiTableCell-root{
        padding-top: 8px;
      }
      &:hover{
        background-color: var(--color-grey-10);
      }
      &:not(:last-of-type){
        th{
          position: relative;
          padding-left: 30px;
          &::before,
          &::after {
            content: '';
            width: 12px;
            height: 2px;
            background-color: var(--color-primary);
            position: absolute;
            top: 50%;
            left: 10px;
            transform: translate(-50%, -50%);
            transition: .3s;
          }
        }
        &.rowClosed{
          th::before,
          th::after {
            background-color: var(--color-grey-70);
          }
          th::after {
            transform: translate(-50%, -50%) rotate(90deg);
          }
        }
      }
    }
  }
  .total-row{
    cursor: pointer;
    background-color: var(--color-grey-10);
    color: var(--color-black-light);
  }
  .MuiTableBody-root{
    .MuiTableRow-root:last-of-type{
      background-color: var(--color-grey-20);
      .classification{
        text-transform: none;
        color: var(--color-black-light);
      }
    }
  }
  .collapse-row{
    background-color: var(--color-highlight-3);
    .MuiTableCell-root{
      padding-left: 30px;
    }
    td.MuiTableCell-root{
      color: var(--color-grey-70);
    }
    &.rowClosed{
      .MuiTableCell-root{
        padding-top: 0;
        padding-bottom: 0;
        border: none;
      }
    }
    .MuiTableCell-root{
      letter-spacing: initial;
    }
  }
}