.survey-modal-wrap{
  padding: 16px 24px;
  &.modal-content{
    height: 80vh;
  }
}
.survey-modal {
  .survey-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;

    &__left {
      display: flex;
      align-items: center;
      gap: 16px;
      svg {
        cursor: pointer;
        display: block;
      }
    }

    &__right {
      display: flex;
      align-items: center;
      padding-right: 40px;
      gap: 8px;
      .action-btn {
        background: #0579df;
        border-radius: 4px;
        color: #fff;
        padding: 8px 16px;
        border: none;
        font-family: "IBMPlexSansRegular", sans-serif;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        cursor: pointer;
      }

      .action-links {
        display: flex;
        align-items: center;
        &::before {
          content: "";
          height: 15px;
          border: 1px solid #ccc;
          margin: 0 15px;
        }
      }
      img {
        margin-right: 18px;
        cursor: pointer;
      }
    }
  }

  .survey-modal-progress {
    margin-bottom: 12px;
    display: flex;
    .title {
      display: flex;
      align-items: center;
      span {
        display: flex;
        align-items: center;
        padding: 5px 8px;
        border-radius: 12px;
        font-family: "IBMPlexSansRegular", sans-serif;
        font-weight: 500;
        font-size: 12px;

        &.old {
          background: #f4f4f4;
        }
        &.new {
          border: 1px solid #e5e6e4;
          color: #847577;
        }
        &.active {
          background: rgba(255, 136, 0, 0.1);
          color: #ff8800;
        }
      }
      &:not(:last-child)::after {
        content: "";
        height: 1px;
        width: 16px;
        background-color: #cfd2cd;
      }
    }
  }

  .survey-modal-title {
    font-weight: 500;
    font-size: 24px;
    font-family: "IBMPlexSansMedium", sans-serif;
    color: #030303;
  }

  .survey-modal-info {
    padding: 10px 0;
    display: flex;

    .title {
      padding-right: 6px;
      color: #847577;

      &:not(:first-child) {
        padding-left: 6px;
      }
    }

    &__divider {
      border-left: 1px solid #cfd2cd;
      margin: 0 6px 0 12px;
      height: 16px;
    }

    img {
      margin: 0 5px;
      width: 16px;
      height: 16px;
      vertical-align: middle;
    }
  }
  .survey-modal-tabs {
    padding: 24px 0 10px;
  }
}

.initiation-modal {
  .stakeholders{
    margin-bottom: 24px;
    h6{
      margin-bottom: 8px;
    }
    &__items{
      display: flex;
      flex-wrap: wrap;
      gap: 8px 16px;
    }
  }
  .btn-group {
    display: flex;
    justify-content: flex-end;

    .action-btn {
      background: #0579df;
      border-radius: 4px;
      color: #fff;
      padding: 8px 16px;
      border: none;
      font-family: "IBMPlexSansRegular", sans-serif;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      cursor: pointer;
      margin-left: 16px;

      &.inverse {
        background: #f4f4f4;
        color: #0579df;
      }
    }
  }
  input{
    background-color: var(--color-grey-10);
  }
  .modal-form .modal-field .MuiFormGroup-root .MuiFormControlLabel-root{
    margin-bottom: 0;
    span{
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}

.details{
  font-family: "IBMPlexSansMedium", sans-serif;
  display: flex;
  gap: 24px;

  .tab-details-info {
    flex: 1;
  }

  .tab-details-title{
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    margin-bottom: 16px;
    color: var(--color-grey-70);
  }

  .tab-details-list{
    display: flex;
    gap: 16px;
    flex-direction: column;
  }

  .tab-details-item{
    display: flex;
    gap: 16px;
    justify-content: space-between;
  }

  .tab-details-caption{
    color: var(--color-black-light);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
  }

  .tab-details-date{
    color: var(--color-black-light);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    text-align: right;
    display: flex;
    gap: 4px;

    & span{
      color: var(--color-grey-70);
    }
  }

  .tab-details-stakeholders{
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
  }

  .tab-details-stakeholder{
    display: flex;
    height: 32px;
    padding: 4px 8px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 16px;
    background: var(--color-grey-10);
    color: var(--color-black-light);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
  }

  .tab-details-picker{
    text-decoration: underline;
    cursor: pointer;

    &:hover{

    }
  }
}