@import "../../assets/styles/mixins";

.Activity {
  display: flex;
  justify-content: space-between;
  transition: 0.3s;
  overflow-x: hidden;
  .collapse-btn{
    cursor: pointer;
    transition: .3s;
    &:hover{
      opacity: .7;
    }
  }
  &.isSidebarHidden{
    .project-left{
      width: 100%;
    }
    .project-right{
      border: none;
      width: 0;
      .side-nav-section{
        left: 100%;
        right: auto;
      }
    }
    .collapse-btn{
      transform: scale(-1, 1);
    }
  }
}

.project-left {
  width: calc(100% - 250px);
  transition: .3s;

  .subTitle{
    font-family: 'IBMPlexSansMedium', sans-serif;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 8px;
  }

  .top-tab {
    margin-bottom: 10px;
    border-bottom: 1px solid #cfd2cd;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0 10px 0;

    &__left {
      h1 {
        font-weight: 500;
        font-size: 24px;
      }
      span {
        color: #847577;
        font-size: 12px;
        font-family: "IBMPlexSansMedium", sans-serif;
      }
    }

    &__right {
      display: flex;
      align-items: center;
      position: relative;

      .close {
        position: relative;
        margin-left: 20px;
        margin-top: -20px;
        margin-right: 15px;
        cursor: pointer;
        filter: invert(48%) sepia(11%) saturate(334%) hue-rotate(302deg)
          brightness(95%) contrast(90%);
      }

      .update-time {
        font-size: 12px;
        font-family: "IBMPlexSansRegular", sans-serif;

        .last-update {
          color: #847577;
        }

        .in-progress {
          color: #ff8800;
          font-weight: 600;
          padding: 0 12px;
        }
      }

      .action-btn {
        display: flex;
        align-items: center;
        cursor: pointer;
        margin: 0 2px;

        svg {
          vertical-align: middle;
          margin: 0 12px;
        }
      }

      .new-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: "IBMPlexSansMedium", sans-serif;
        font-size: 16px;
        color: #fff;
        background-color: #0579df;
        border-radius: 5px;
        border: none;
        padding: 0 20px;
        margin: 0 5px;
        cursor: pointer;

        &:hover {
          background-color: #309af6;
        }
      }
    }
  }

  .active-milestone {
    margin-bottom: 32px;
    display: flex;
    flex-direction: column;
    font-size: 14px;
    padding: 0 0 10px 0;

    &__head {
      display: flex;
      font-weight: 600;
      font-size: 13px;
      align-items: center;
      text-transform: uppercase;

      svg {
        margin-right: 20px;
      }
    }
    .active-milestone-wrap{
      display: flex;
      gap: 16px;
      overflow-y: auto;
      @include scrollbar(true, "&");
      &:hover{
        @include scrollbar(false, "&");
      }
      .status{
        margin-bottom: 8px;
      }
      h2{
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &__card {
      /* Auto Layout */

      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 16px;
      position: relative;
      width: 320px;
      margin-top: 20px;
      background: #fffbdb;
      border: 1px solid #fbe646;
      box-sizing: border-box;
      border-radius: 4px;
      cursor: pointer;

      .more-btn {
        height: 15px;
        position: absolute;
        right: 20px;
        top: 20px;
        cursor: pointer;
      }

      .content {
        margin-top: 15px;
        display: flex;
        align-items: center;

        svg {
          padding-right: 5px;
        }

        &__divider {
          border-left: 1px solid #cfd2cd;
          margin: 0 8px;
          height: 16px;
        }

        &__name {
          width: 95px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          display: block;
        }
      }
    }
  }

  .second-tab {
    margin-bottom: 32px;
    border-bottom: 1px solid #cfd2cd;
    display: flex;
    flex-direction: column;
    font-size: 14px;
    padding: 0 0 10px 0;

    .title {
      padding-left: 8px;
      padding-right: 6px;
      color: #847577;
    }

    .generate {
      color: #0579df;
      padding-right: 12px;
    }

    &__divider {
      border-left: 1px solid #cfd2cd;
      margin: 0 6px 0 12px;
      height: 16px;
    }

    &__first-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 4px 0;

      div {
        display: flex;
        align-items: center;
      }
    }

    &__second-row {
      display: flex;
      padding: 4px 0;
    }
  }
  .table-content{
    max-height: calc(100vh - 100px);
  }
}

.project-right {
  width: 240px;
  flex: none;
  transition: .3s;
  // height: 500px;
}
