@import "../../../../assets/styles/mixins";
.side-nav-section {
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 80px;
  //width: 236px;
  right: 0;
  left: calc(100% - 264px);
  width: 264px;
  height: calc(100% - 100px);
  transition: 0.3s;
  overflow: auto;
  border-left: 1px solid var(--color-grey-30);
  @include scrollbar(true, "&");
  &:hover{
    @include scrollbar(false, "&");
  }

  .menu-list {
    display: flex;
    width: 100%;
    flex-direction: column;

    .link-divider {
      margin: 14px 0 14px 14px;
      border-top: 1px solid var(--color-grey-30);
    }

    .link-subtitle {
      margin: 14px 0 14px 14px;
      color: #847577;
      font-family: "IBMPlexSansRegular", sans-serif;
      font-size: 12px;
      text-transform: uppercase;
    }

    .links {
      display: flex;
      flex-direction: column;
      width: 100%;
      z-index: 600;
      margin-bottom: 8px;

      .link.menu-link-active {
        color: var(--color-primary-tertiary);
        path {
          fill: var(--color-primary-tertiary);
        }
      }

      .link.menu-link-active:hover {
        text-decoration: none;
        color: #0579df;
        border-color: rgba(97, 37, 81, 0.1);
      }

      .link {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        font-family: "IBMPlexSansMedium", sans-serif;
        text-decoration: none;
        line-height: 16px;
        color: #030303;
        padding: 8px 0 8px 16px;
        margin-bottom: 16px;
        border-top-right-radius: 16px;
        border-bottom-right-radius: 16px;
        transition: none;
        cursor: default;
        .link__wrap{
          display: flex;
          align-items: center;
          cursor: pointer;
        }

        &:hover {
          color: var(--color-primary-tertiary);
        }

        &:first-child {
          margin-top: 0;
        }

        span {
          white-space: nowrap;
          transition: 0.3s;
          // transition-delay: 0.3s;
        }

        svg {
          margin: 0 13px;
          display: flex;
        }
      }
      .has-icon {
        padding: 0;
      }
    }
  }
}
