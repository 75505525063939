.drag-and-drop-component {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  overflow-x: hidden;

  > div {
    > div {
      padding: 0 !important;
    }
  }
}